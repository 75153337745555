/* Vector 1 */

.vec1 {
  position: absolute;
  width: 0px;
  height: 133px;
  left: 207px;
  top: 235px;

  border: 27px solid rgba(9, 64, 206, 0.79);
}
/* Vector 2 */

.vec2 {
  position: absolute;
  width: 0px;
  height: 133px;
  left: 207px;
  top: 448px;

  border: 27px solid rgba(206, 9, 9, 0.79);
}
/* Rectangle 1 */

.rec1 {
  position: absolute;
  width: 204px;
  height: 117px;
  left: 105px;
  top: 368px;

  background: #000000;
}
/* Rectangle 2 */
.rec2 {
  position: absolute;
  width: 204px;
  height: 117px;
  left: 105px;
  top: 564px;

  background: #000000;
}
/* CHOOSE YOUR INVESTMENT CHAMPSCHAMPS */
.txt1 {
  position: absolute;
  width: 168px;
  height: 96px;
  left: 123px;
  top: 382px;

  font-family: Fugaz One;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  color: #ffffff;
}
/* WE PERSONALIZE YOUR PORTFOLIO */
.txt2 {
  position: absolute;
  width: 168px;
  height: 96px;
  left: 123px;
  top: 595px;

  font-family: Fugaz One;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  color: #ffffff;
}
